@import "functions/adjust-colors";

// Grey

$grey-5: #F1F3F5;
$grey-10: #E1E5EA;
$grey-20: #C3CCD5;
$grey-30: #A6B2BF;
$grey-40: #8899AA;
$grey-50: #6A8095;
$grey-60: #556677;
$grey-70: #404D59;
$grey-80: #2B333C;
$grey-90: #0B0D0F;

// Navy

$navy-5: #EBF0F9;
$navy-10: #D8E2F3;
$navy-20: #B0C5E8;
$navy-30: #89A8DC;
$navy-40: #628BD0;
$navy-50: #3B6EC4;
$navy-60: #2F589D;
$navy-70: #234276;
$navy-80: #172B4D;
$navy-90: #12213B;

// Red

$red-5: #FAEAEA;
$red-10: #F6D5D5;
$red-20: #ECACAC;
$red-30: #E38282;
$red-40: #DA5858;
$red-50: #D12E2E;
$red-60: #A72525;
$red-70: #7D1C1C;
$red-80: #541213;
$red-90: #2A0909;

// Orange

$orange-5: #FFF1E5;
$orange-10: #FFE3CC;
$orange-20: #FFC899;
$orange-30: #FFAC66;
$orange-40: #FF9133;
$orange-50: #FF7500;
$orange-60: #CC5E00;
$orange-70: #994600;
$orange-80: #662F00;
$orange-90: #1A0C00;

// Blue

$blue-5: #EBF3FA;
$blue-10: #D7E6F4;
$blue-20: #AFCEE9;
$blue-30: #86B5DF;
$blue-40: #5E9DD4;
$blue-50: #3684C9;
$blue-60: #2B6AA1;
$blue-70: #204F79;
$blue-80: #163550;
$blue-90: #0B1A28;

// Green

$green-5: #EBF9F3;
$green-10: #D8F3E8;
$green-20: #B1E7D0;
$green-30: #89DCB9;
$green-40: #62D0A1;
$green-50: #36B47F;
$green-60: #2F9D6E;
$green-70: #247552;
$green-80: #06140E;
$green-90: #06140E;

// Defaults

$grey: $grey-60;
$navy: $navy-80;
$red: $red-40;
$orange: $orange-30;
$blue: $blue-50;
$green: $green-50;
$white: #FFFFFF;

// UI

$bg-color:          $grey-5;
$primary-color:     $navy;
$secondary-color:   $grey;
$border-color:      $grey-10;