@import './fonts/muli.css';
@import './styles/vars';

html,
body,
#root,
.App,
.Router {
  height: 100%;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  background-color: $bg-color;
  color: $primary-color;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
  padding: 0;
}

h1 {
  font-weight: $font-weight-bold;
  margin-bottom: $mb-1;
}

h3 {
  font-size: $header-three;
  font-weight: $font-weight-semibold;
}

a {
  color: $blue-60;
  font-weight: $font-weight-semibold;
  text-decoration: underline;
  transition: all 0.3s ease;
  &:hover {
    color: $blue-80;
    text-decoration: underline;
  }
}

.content-margin {
  margin: $m-5;
}

.gecko-ui-field {
  label {
    color: $primary-color;
  }

  .form-control {
    border: $input-border;
    font-weight: $input-font-weight;
    &::-webkit-input-placeholder {
      color: $input-placeholder-color;
      font-weight: $input-placeholder-font-weight;
    }
    &::-moz-placeholder {
      color: $input-placeholder-color;
      font-weight: $input-placeholder-font-weight;
    }
    &:-ms-input-placeholder {
      color: $input-placeholder-color;
      font-weight: $input-placeholder-font-weight;
    }
    &:-moz-placeholder {
      color: $input-placeholder-color;
      font-weight: $input-placeholder-font-weight;
    }
    &:focus {
      border: $input-focus-border;
      box-shadow: $input-focus-box-shadow;
    }
  }

  .Select__control {
    border: $input-border;
    font-size: 1rem;
    height: calc(1.875em + .8rem + 1px);
    .Select__dropdown-indicator {
      color: $primary-color;
    }
    .Select__indicator-separator {
      border-color: $border-color;
    }
    &.Select__control--is-focused {
      border: 1px solid #6a8095;
      box-shadow: inset 0 0 0 0.025rem #6a8095;
    }
    .Select__value-container {
      padding-left: 12px;
    }
    &:hover {
      border: $input-border;
    }
  }
}

.gecko-ui-field-type-toggle input + label {
  height: 30px;
  width: 56px;
  &:after {
    width: 56px;
    height: 30px;
    box-shadow: none;
    border: 1px solid $grey-30;
    background-color: $white;
    cursor: pointer;
  }
  &:before {
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    background-color: $grey-30;
    box-shadow: none;
    cursor: pointer;
  }
}

.gecko-ui-field-type-toggle input:checked + label {
  &:after {
    background-color: $green;
    border-color: $green;
  }
  &:before {
    left: 30px;
    border-color: transparent;
    box-shadow: none;
    background-color: $white;
  }
}

.gecko-ui-modal-wrapper {
  .modal-backdrop {
    background-color: $primary-color;
    &.show {
      opacity: .9;
    }
  }
  .modal-header {
    align-items: center;
    padding: $py-4 $px-5;
    .modal-title {
      padding: 0;
      font-size: $header-two;
      font-weight: $font-weight-semibold;
    }
    .modal-close {
      font-size: $icon-size-large;
      color: $primary-color;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      opacity: 1;
      font-size: $header-two;
      &:hover {
        color: $secondary-color;
      }
    }
  }
  .modal-body {
    padding: $py-5 $px-5;
  }
}

.recharts-responsive-container {
  .recharts-tooltip-cursor {
    cursor: pointer !important;
  }
}

.recharts-default-tooltip {
  border-radius: 4px !important;
  border: 1px solid $border-color !important;
  padding: 16px !important;
}

.gecko-ui-dropdown .dropdown-menu .dropdown-item {
  margin-bottom: $mb-3;
  border: none;
  padding: 0;
  font-weight: 500;
  color: $primary-color;
  transition: all 0.3s ease;
  &:hover {
    color: $secondary-color;
  }
  &.gecko-dropdown-item-4{
    color: $red-50;
    &:hover {
      color: $red-60;
    }
  }
  .gecko-ui-icon {
    margin-right: $mr-1;
    width: 24px;
    text-align: center;
  }
}

.__react_component_tooltip {
  font-family: $font-family-base !important;
  font-weight: 700 !important;
  background-color: #222 !important;
  border-radius: 4px !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 14px !important;
  opacity: 1 !important;
}

.tooltip-inner {
  background-color: #222;
}

.bs-tooltip-auto[x-placement^=top] .arrow:before, 
.bs-tooltip-top .arrow:before {
  border-top-color: #222;
}

.bs-tooltip-auto[x-placement^=left] .arrow:before, 
.bs-tooltip-left .arrow:before {
  border-left-color: #222;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before, 
.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #222;
}

.bs-tooltip-auto[x-placement^=right] .arrow:before, 
.bs-tooltip-right .arrow:before {
  border-right-color: #222;
}