.AuthContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ComponentContainer {
    flex: 1 0 auto;
  }
  .FooterContainer {
    flex-shrink: 0;
  }
}