@import '../../styles/vars';

.GeckoBuilder-Button {
  span {
    &:not(:first-of-type) {
      margin-left: $btn-icon-margin-left;
    }
  }
}

.btn {
  font-size: $btn-font-size !important;
  font-weight: $btn-font-weight !important;
  padding: $btn-padding;
  border-radius: $btn-border-radius;
  border: $btn-border;
  &.btn-primary {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
    color: $btn-primary-color;
    &:hover {
      background-color: $btn-primary-hover-bg;
      border-color: $btn-primary-hover-bg;
    }
    &:focus {
      border: $btn-primary-focus-border;
      outline: $btn-primary-focus-outline;
      box-shadow: none !important;
    }
    &.disabled,
    &:disabled {
      color: $btn-secondary-color;
      background-color: #A6B2BF;
      border-color: #A6B2BF;
    }
  }
  &.btn-secondary {
    background-color: $btn-secondary-bg;
    color: $btn-secondary-color;
    border-color: $btn-secondary-bg;
    &:hover {
      background-color: $btn-secondary-hover-bg;
      border-color: $btn-secondary-hover-bg;
    }
    &:focus {
      border: $btn-secondary-focus-border;
      outline: $btn-secondary-focus-outline;
      box-shadow: none !important;
    }
  }
  &.btn-danger {
    background-color: $btn-danger-bg;
    color: $btn-danger-color;
    border-color: $btn-danger-bg;
    &:hover {
      background-color: $btn-danger-hover-bg;
      border-color: $btn-danger-hover-bg;
    }
    &:focus {
      border: $btn-danger-focus-border;
      outline: $btn-danger-focus-outline;
      box-shadow: none !important;
    }
  }
}
