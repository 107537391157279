@import '../../styles/vars';

.PageMask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2147483646;
  background-color: $primary-color;
  opacity: 0.6;
  overflow: auto;
}