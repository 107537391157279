@import '../../styles/vars';

.GeckoBuilder-Builder {
  &.content-margin {
    padding-right: 96px;
  }
  .builderComponent {
    .builderComponent-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding-bottom: $p-2;
      border-bottom: $border-default;

      &.active {
        margin-bottom: $m-3;
      }

      .builderComponent-header-details {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        display: flex;
        align-items: center;
        span {
          padding-right: $p-2;
        }
      }

      .builderComponent-header-actions {
        display: flex;
        align-items: center;

        .moveComponent {
          margin-right: $m-2;
          .moveComponent-arrow {
            background-color: transparent;
            border: none;
            color: $secondary-color;
          }
        }

        .delete {
          margin-right: $btn-icon-margin-right;
        }
      }
    }

    &:not(:first-of-type) {
      .builderComponent-header {
        padding-top: $p-2;
      }
    }

    .FieldLink {
      font-size: $font-size-base;
      margin-top: $spacer / 2;
      display: inline-block;
      text-decoration: none;
    }

    .FieldLink-error {
      display: block;
      color: $red;
      font-size: $font-size-base;
    }

    #field_infoField_label {
      display: none;
    }
    .gecko-ui-alert {
      padding: $p-2 !important;
      margin-top: $m-2;
      margin-bottom: 0 !important;
      font-size: $font-size-base;
      color: $blue-50 !important;
    }

    .MobileSelect {
      position: relative;
      span {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .completionMessage {
      font-size: $header-two;
      font-weight: $font-weight-bold;
    }

    .actionMessage {
      font-size: $font-size-base;
      margin-bottom: $m-3;
    }

    .warningMessage {
      font-size: $font-size-base;
    }
  }

  .btn-add {
    margin-top: $mt-3;
  }

  .WidgetDetailsContent,
  .AddBuilderComponentContainer {
    position: relative;

    #field_openMessage_label {
      margin-bottom: 0;
    }
    .gecko-ui-alert {
      padding: $p-2 !important;
      margin-top: $m-2;
      margin-bottom: 0 !important;
      font-size: $font-size-base;
      color: $blue-50 !important;
    }

    .Description {
      display: block;
      color: $secondary-color;
      font-size: $font-size-small;
      margin-bottom: 4px;
    }
  }

  .TourCard {
    position: absolute;
    &.ComponentTypeTour {
      top: -150px;
      left: 215px;
    }
    &.ComponentDetailsTour {
      right: -100px;
      top: 65px;
    }
    &.AddAnotherComponentTour {
      top: 65px;
      left: -35px;
    }
    &.BrandWidgetTour {
      right: -100px;
      top: 65px;
    }
    &.LivePreviewTour {
      position: fixed;
      bottom: 15px;
      right: 100px;
      z-index: 2147483647;
    }
  }
}

@media (max-width: 720px) {
  .GeckoBuilder-Builder {
    .TourCard {
      max-width: 200px;
      &.ComponentTypeTour {
        top: -190px;
        left: -25px;
      }
      &.ComponentDetailsTour {
        left: -25px;
        top: -190px;
      }
      &.BrandWidgetTour {
        left: -25px;
        top: -190px;
      }
    }
  }
}

@media (max-width: 480px) {
  .GeckoBuilder-Builder {
    &.content-margin {
      padding-right: 0;
    }
  }
}
