@mixin center-screen-position () {
  position: absolute;
  top: 50vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

@mixin center-align () {
  display: flex;
  justify-content: space-between;
  align-items: center;
}