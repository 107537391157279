@import '../../styles/vars';

@mixin icon-style() {
  .gecko-ui-icon {
    margin-right: $btn-icon-margin-right;
    font-size: $icon-size-base;
  }
}

.GeckoBuilder-Header {
  position: relative;
  padding: $header-padding;
  background-color: $header-bg;
  z-index: 10;
  width: 100%;
  @include center-align();

  .GeckoBuilder-Header-Nav {
    ul {
      display: flex;
      li {
        a {
          background-color: $header-link-bg;
          color: $header-link-color;
          margin: $header-link-margin;
          font-size: $header-link-font-size;
          font-weight: $header-link-font-weight;
          border-radius: $header-link-border-radius;
          border: $header-link-border;
          padding: $header-link-padding;
          display: inline-block;
          text-decoration: none;
          @include icon-style();
          &:hover,
          &[aria-current] {
            background: $header-link-primary-hover-bg;
            color: $header-link-primary-hover-color;
            text-decoration: none;
          }
          &:focus {
            background: $header-link-primary-hover-bg;
            color: $header-link-primary-hover-color;
            outline-color: $header-link-primary-focus;
            border-color: $white;
          }
        }
      }
    }
  }

  .GeckoBuilder-Header-Logout {
    color: $header-link-logout-color;
    font-size: $header-link-font-size;
    font-weight: $header-link-font-weight;
    border-radius: $header-link-border-radius;
    border: $header-link-border;
    padding: $header-link-padding;
    display: inline-block;
    justify-content: flex-end;
    text-decoration: none;
    @include icon-style();
    &:hover {
      background: $header-link-logout-hover-bg;
      color: $header-link-logout-hover-color;
      text-decoration: none;
    }
    &:focus {
      background: $header-link-logout-hover-bg;
      color: $header-link-logout-hover-color;
      outline-color: $header-link-logout-focus;
      border-color: $white;
    }
  }

  .GeckoBuilder-Mobile-Nav-Open-Button {
    display: none;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: $blue-5;
    cursor: pointer;
    color: $blue-60;
  }

  .GeckoBuilder-Mobile-Nav-Mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.1;
    z-index: 2147483646;
  }

  .GeckoBuilder-Mobile-Nav-Drawer {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    max-width: 320px;
    z-index: 2147483647;
    background-color: $white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: $p-2 $p-2 $p-5 $p-2;

    .Drawer-Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $m-5;

      .GeckoBuilder-Mobile-Nav-Close-Button {
        border: none;
        background-color: transparent;
      }
    }

    .GeckoBuilder-Mobile-Nav-Items {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      .GeckoBuilder-Header-Nav {
        ul {
          flex-direction: column;
          li {
            margin-bottom: $m-2;
            a {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }
  
      .GeckoBuilder-Header-Logout {
        width: 100%;
        display: flex;
        justify-content: center;      
      }
    }
  }

  @media (max-width: 720px) {
    > .GeckoBuilder-Header-Nav,
    > .GeckoBuilder-Header-Logout {
      display: none;
    }

    .GeckoBuilder-Mobile-Nav-Open-Button, .GeckoBuilder-Mobile-Nav-Mask {
      display: initial;
    }
    .GeckoBuilder-Mobile-Nav-Drawer {
      display: flex;
    }
  }
}
