@import '../../styles/vars';

.GeckoBuilder-Card {
  background-color: $card-bg;
  border-radius: $card-border-radius;
  margin-bottom: $card-margin-botom;

  .GeckoBuilder-Card-Header,
  .GeckoBuilder-Card-Body,
  .GeckoBuilder-Card-Footer {
    padding: $card-padding;
  }

  .GeckoBuilder-Card-Header {
    @include center-align();
    cursor: pointer;

    .step-number {
      color: $step-card-number-color;
      background-color: $step-card-number-bg;
      border-radius: $step-card-number-border;
      font-weight: $step-card-header-font-weight;
      height: $step-card-number-height;
      width: $step-card-number-width;
      text-align: center;
      line-height: $step-card-number-line-height;
      margin-right: $step-card-number-margin; 
    }

    .step-name {
      flex-grow: 2;
      font-weight: $step-card-header-font-weight;
      color: $primary-color;
    }

    .step-progress {
      width: $step-card-indicator-width;
      height: $step-card-indicator-height;
      border-radius: $step-card-number-border;
      text-align: center;
      line-height: $step-card-indicator-line-height;
      font-size: $step-card-indicator-font-size;

      &.complete {
        border: $border-width-default $border-style-default $step-card-complete-color;
        color: $step-card-complete-color;
      }

      &.incomplete {
        border: $border-width-default $border-style-default $step-card-incomplete-color;
        color: $step-card-incomplete-color;
      }
    }
  }

  .GeckoBuilder-Card-Body {
    border-top: $border-default;
    .gecko-ui-fields {
       margin: 0 -16px;
    }
  }

  .GeckoBuilder-Card-Footer {
    display: flex;
    justify-content: flex-end;
    border-top: $border-default;

    button:last-child {
      margin-left: $m-2;
    }
  }
}
