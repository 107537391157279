@import './colors';
@import './mixins';

// SIZING 

$spacer: 8px !default;
$spacer-small: $spacer / 2;

$m-1: $spacer; //8px
$m-2: $spacer * 1.5; // 12px
$m-3: $spacer * 2; // 16px
$m-4: $spacer * 2.5; // 20px
$m-5: $spacer * 3; // 24px

$ml-1: $m-1;
$ml-2: $m-2;
$ml-3: $m-3;
$ml-4: $m-4;
$ml-5: $m-5;

$mr-1: $m-1;
$mr-2: $m-2;
$mr-3: $m-3;
$mr-4: $m-4;
$mr-5: $m-5;

$mt-1: $m-1;
$mt-2: $m-2;
$mt-3: $m-3;
$mt-4: $m-4;
$mt-5: $m-5;

$mb-1: $m-1;
$mb-2: $m-2;
$mb-3: $m-3;
$mb-4: $m-4;
$mb-5: $m-5;

$mx-1: $m-1;
$mx-2: $m-2;
$mx-3: $m-3;
$mx-4: $m-4;
$mx-5: $m-5;

$my-1: $m-1;
$my-2: $m-2;
$my-3: $m-3;
$my-4: $m-4;
$my-5: $m-5;

$p-1: $spacer; //8px
$p-2: $spacer * 1.5; // 12px
$p-3: $spacer * 2; // 16px
$p-4: $spacer * 2.5; // 20px
$p-5: $spacer * 3; // 24px

$pl-1: $m-1;
$pl-2: $m-2;
$pl-3: $m-3;
$pl-4: $m-4;
$pl-5: $m-5;

$pr-1: $m-1;
$pr-2: $m-2;
$pr-3: $m-3;
$pr-4: $m-4;
$pr-5: $m-5;

$pt-1: $m-1;
$pt-2: $m-2;
$pt-3: $m-3;
$pt-4: $m-4;
$pt-5: $m-5;

$pb-1: $m-1;
$pb-2: $m-2;
$pb-3: $m-3;
$pb-4: $m-4;
$pb-5: $m-5;

$px-1: $m-1;
$px-2: $m-2;
$px-3: $m-3;
$px-4: $m-4;
$px-5: $m-5;

$py-1: $m-1;
$py-2: $m-2;
$py-3: $m-3;
$py-4: $m-4;
$py-5: $m-5;

// TYPOGRAPHY 

$font-size-base:        14px;
$font-size-large:       16px;
$font-size-small:       12px;
$font-size-smaller:     10px;

$header-one:            24px;
$header-two:            18px;
$header-three:          $font-size-large;

$icon-size-base:        15px;
$icon-size-small:       13px;
$icon-size-smaller:     11px;
$icon-size-large:       17px;
$icon-size-larger:      19px;

$font-weight-base:      400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;

$font-stack-main:       'Muli', sans-serif;
$font-family-base:      $font-stack-main;
$font-family-icons:     'Font Awesome 5 Pro';

// UI

// UI Defaults

$border-radius:         $spacer / 2;
$border-circle:         50%;
$border-style-default:  solid;
$border-width-default:  1px;
$border-default:        $border-width-default $border-style-default $border-color;

// Buttons 

$btn-padding:                   $py-1 $px-2;
$btn-font-size:                 $font-size-base;
$btn-font-weight:               $font-weight-bold;
$btn-border-radius:             $border-radius;
$btn-border:                    $border-width-default $border-style-default transparent;
$btn-icon-margin-right:         $mr-1;
$btn-icon-margin-left:          $ml-1;

$btn-primary-bg:                $navy-50;
$btn-primary-hover-bg:          $navy-60;
$btn-primary-border:            $navy-50;
$btn-primary-color:             $white;
$btn-primary-focus-border:      $border-width-default $border-style-default $white;
$btn-primary-focus-outline:     0.125rem solid $btn-primary-border;

$btn-secondary-bg:              $grey-10;
$btn-secondary-hover-bg:        $grey-20;
$btn-secondary-border:          $grey-50;
$btn-secondary-color:           $primary-color;
$btn-secondary-focus-border:    $border-width-default $border-style-default $white;
$btn-secondary-focus-outline:   0.125rem solid $btn-secondary-border;

$btn-danger-bg:                 $red-50;
$btn-danger-hover-bg:           $red-60;
$btn-danger-border:             $red-40;
$btn-danger-color:              $white;
$btn-danger-focus-border:       $border-width-default $border-style-default $white;
$btn-danger-focus-outline:      0.125rem solid $btn-danger-border;

$btn-naked-bg:                  transparent;
$btn-naked-primary-color:       $primary-color;
$btn-naked-primary-hover-bg:    $blue-5;
$btn-naked-primary-focus:       $blue-50;
$btn-naked-primary-hover-color: $blue-60;
$btn-naked-danger-color:        $red-50;
$btn-naked-danger-hover-bg:     $red-5;
$btn-naked-danger-focus:        $red-40;
$btn-naked-danger-hover-color:  $red-60;

// Form Fields

$input-border:                      $border-width-default $border-style-default $grey-20;
$input-border-radius:               $border-radius;
$input-placeholder-color:           $secondary-color;
$input-font-weight:                 $font-weight-medium;
$input-placeholder-font-weight:     $input-font-weight;
$input-focus-border:                $border-width-default $border-style-default $grey-50;
$input-focus-box-shadow:            inset 0 0 0 0.025rem $grey-50;

// Radio Buttons

$radio-button-border:               $input-border;
$radio-button-margin:               $mr-1;
$radio-button-border-radius:        $input-border-radius;
$radio-button-hover-bg:             $blue-5;
$radio-button-hover-color:          $blue-60;
$radio-button-hover-border:         $blue-40;
$radio-button-focus-border:         $radio-button-hover-border;
$radio-button-focus-box-shadow:     none;
$radio-button-focus-bg:             $radio-button-hover-bg;

// // Header

$header-padding:                    $p-2 $p-5;
$header-bg:                         $white;
$logo-width:                        106px;

$header-link-padding:               $btn-padding;
$header-link-margin:                0 $spacer-small; 
$header-link-font-size:             $btn-font-size;
$header-link-font-weight:           $btn-font-weight;
$header-link-border-radius:         $btn-border-radius;
$header-link-border:                $btn-border;
$header-link-color:                 $btn-naked-primary-color;
$header-link-bg:                    $btn-naked-bg;

$header-link-primary-hover-bg:      $btn-naked-primary-hover-bg;
$header-link-primary-focus:         $btn-naked-primary-focus;
$header-link-primary-hover-color:   $btn-naked-primary-hover-color;

$header-link-logout-color:          $btn-naked-danger-color;
$header-link-logout-hover-bg:       $btn-naked-danger-hover-bg;
$header-link-logout-focus:          $btn-naked-danger-focus;
$header-link-logout-hover-color:    $btn-naked-danger-hover-color;


// // Footer

$footer-padding: $p-3 $p-5;
$footer-bg: $white;

// // Card

$card-bg:               $white;
$card-border-radius:    $border-radius;
$card-margin-botom:     $spacer * 2;
$card-padding:          $py-4 $px-5;

// // Step Card

$step-card-incomplete-color:        $grey-30;
$step-card-complete-color:          $green-60;
$step-card-number-bg:               $navy;
$step-card-number-color:            $white;
$step-card-number-line-height:      24px;
$step-card-number-margin:           $m-1;
$step-card-number-height:           $step-card-number-line-height;
$step-card-number-width:            $step-card-number-line-height;
$step-card-number-border:           $border-circle;
$step-card-indicator-line-height:   32px;
$step-card-indicator-height:        $step-card-indicator-line-height;
$step-card-indicator-width:         $step-card-indicator-line-height;
$step-card-indicator-font-size:     $font-size-base;
$step-card-header-font-weight:      $font-weight-bold;