@import '../../../../styles/vars';

.TourCard {
  font-size: $font-size-base;
  position: relative;
  z-index: 2147483647;
  background-color: $white;
  border-radius: $border-radius;
  width: 300px;
  box-shadow: 0px 0px 20px 0px rgba(42, 51, 56, 0.25);

  .Header {
    padding: $p-2;
    font-weight: $font-weight-semibold;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: none;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    z-index: 2147483647;
    padding: $p-2;

    p {
      margin-bottom: $m-2;
    }

    .GeckoBuilder-Button {
      align-self: flex-end;
    }
  }

  .Pointer {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: $white;

    &.left {
      left: -12px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
    }

    &.right {
      right: 0;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
    }

    &.bottom {
      bottom: -12px;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }

    &.top {
      top: 0;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }
  }
}