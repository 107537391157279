.ColorPicker.form-control {
  display: flex;
  align-items: center;

  input.form-control:first-of-type {
    border: none;
    padding: 0;
    height: 1.875rem;
    max-width: 1.875rem;
  }
  input.form-control:not(:first-of-type) {
    height: 1.875rem;
    border: none;
  }
}