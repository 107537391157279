@import '../../styles/vars';
@import '../../styles/colors';

.EmbedModal {
  code {
    position: relative;
    display: block;
		margin-bottom: 16px;
  }
  button {
    &.btn.btn-secondary {
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
  pre {
    background: #eff0f2;
    padding: 16px 60px 16px 16px;
    border-radius: 4px;
    border: 1px solid #a6b2bf;
    font-size: 14px;
  }
  .EmbedModal-tag {
    color: #bd4147;
  }
  .EmbedModal-attribute {
    color: #f18c16;
  }
  .EmbedModal-string {
    color: #249d7f;
  }
  .EmbedModal-object {
    color: #2795EE;
  }
	.gecko-ui-field-type-custom {
    position: relative;
     
    .GeckoBuilder-Button {
      float: right;
      margin-top: $m-2;
    }

    .errorIcon {
      cursor: pointer;
      color: $red-50;
      position: absolute;
      right: 15px;
      top: 36px;
    }
	}
	input {
		margin-right: 8px;
	}
}
