@import '../../styles/vars';

.Widgets {
    padding: $p-5;
    .gecko-ui-card.card {
        border: none !important;
        background: none !important;
        .card-header {
            background: none !important;
            padding: 0 !important;
            border-bottom: none !important;
            .header-title {
                margin-bottom: $mb-5 !important;
                font-weight: $font-weight-bold;
            }
        }
        .card-footer {
            background: none;
            padding: 0 !important;
            border: none !important;
            color: $secondary-color;
            font-weight: $font-weight-semibold;
        }
    }
    .table-bordered>:not(caption)>*,
    .table>:not(:first-child) {
        border: none;
    }
     
    .gecko-ui-table.table {
        thead {
            th {
                border: none;
                background: none;
                color: $secondary-color;
                font-weight: $font-weight-semibold;
            }
        }
        tbody {
            tr {
                background: $white;
                border-bottom: 8px solid $grey-5;
                td {
                    border: none;
                    color: $primary-color;
                    font-size: $font-size-base;
                    line-height: inherit;
                }
                &:hover {
                    td {
                        box-shadow: none;
                        background: $white;
                    }
                }
            }
        }
    }
    .gecko-ui-table-cell-name {
        cursor: pointer;
    }
    .gecko-ui-table-cell-openclickstats > div {
        svg {
            margin-left: $ml-1;
        }
    }
    .gecko-ui-table-cell-componentclickstats > div,
    .gecko-ui-table-cell-openclickstats > div {
        display: flex;
        align-items: center;
        span {
            margin-right: $mr-3;
        }
        .no-data {
            width: 100%;
            text-align: center;
        }
        svg {
            height: 40px;
            width: 100%;
        }
    }

    .noWidgets {
        height: calc(100vh - 156px);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
        justify-content: center;
        .noWidgets-image-container {
            max-width: 100%;
            padding: $m-5 0;
            img {
                width: 100%;
            }
        }
        h2 {
            margin-bottom: $m-2;
            font-size: $header-two;
            flex: 0;
        }
        p {
            font-size: $font-size-base;
            margin-bottom: $spacer * 4;
            text-align: center;
        }
        .noWidgets-options-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            button {
                margin-bottom: $m-1;
                margin-left: $m-1 * 0.5;
                margin-right: $m-1 * 0.5;
            }
        }
    }
}