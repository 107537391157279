@import '../../../../styles/vars';

.TourModal {
  .tourVideo {
    position: relative;
    padding-bottom: 62.5%;
    margin-bottom: $m-3;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .GeckoBuilder-Button {
    float: right;
  }
}
