@import '../../styles/vars';

.Login {
    background-color: white;
    background-image: url('./widget-bg.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;

    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5%;
    
    > * {
        margin: 15px;
        text-align: center;
    }
    > a {
        width: 220px;
        max-width: initial;
        margin-left: 230px;
        margin-right: 0;
        @media (max-width: 1243px) {
            margin-left: 0;
        }
        @media (max-width: 768px) {
            width: 160px;
        }
        .GeckoBuilder-Logo {
            max-width: 100%;
        }
    }
    > .Login-form {
        background: white;
        padding: 40px;
        border-radius: 5px;
        max-width: 650px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px -1px 7px 2px rgba(0,0,0,0.2);
        > header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .Login-email {
            margin: 8px 0px;
            position: relative;
            width: 100%;
            label[for="email"] {
                font-weight: $font-weight-semibold;
                padding: 14px 1rem;
                color: $primary-color;
            }
            input[name="email"] {
                width: 100%;
                border-radius: $input-border-radius;
                border: $input-border;
                font-weight: $font-weight-semibold;
                height: 50px;
                padding: 1rem;
                color: $primary-color;
                &:focus {
                    box-shadow: $input-focus-box-shadow;
                    border: $input-focus-border;
                }
            }
        }
        .Login-terms {
            margin: 0 0 16px;
            font-size: 14px;
            display: inline-flex;
            align-items: center;

            input {
                margin-right: 10px;
            }

            span {
                text-align: left;
            }
        }
        .Login-switch-message {
            margin-top: $m-2;
            font-size: $font-size-base;
        }
    }

    @media only screen and (max-width: 1243px) {
        background-color: white;
        background-image: url('./widget-bg-small.jpg');
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 0px;
        align-items: center;
        > .GeckoBuilder-Logo {
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 767px) {
        background: $grey-5;
    }

    .form-floating {
        > .form-control {
            &:focus,
            &:not(:placeholder-shown) {
                padding-top: 1.625rem !important;
                padding-bottom: 0.625rem !important;
            }
        }
    }
}

.Login-landing-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: auto;
    justify-content: center;
    img {
        max-width: 90%;
        margin-bottom: $mb-5;
    }

    div {
        max-width: 90%;
    }
}