@import '../../../styles/vars';

.ButtonRadio {
    display: flex;
    flex-wrap: wrap;
    [role="radio"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: $radio-button-margin;
        justify-content: center;
        background: white;
        border: $radio-button-border;
        border-radius: $radio-button-border-radius;
        cursor: pointer;
        height: 50px;
        width: 50px;
        transition: all 0.3s ease;
        i {
            display: block;
        }
        &[aria-checked="true"],
        &:hover {
            color: $radio-button-hover-color;
            border-color: $radio-button-hover-border;
            background: $radio-button-hover-bg;
        }
        &:focus {
            border-color: $radio-button-focus-border;
            outline: $radio-button-focus-box-shadow;
            background-color: $radio-button-focus-bg;
        }
    }
    div {
        position: relative;
        .gecko-ui-label {
            border: none;
            position: absolute;
            top: -1px;
            left: -1px;
        }
    }
    &.ButtonRadioLg {
        [role="radio"] {
            width: 134px;
            height: 76px;
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            i {
                margin-bottom: $spacer-small;
            }
        }
    }
}