@import '../../styles/vars';


.GeckoEngagementWidget-Footer {
  position: relative;
  padding: $footer-padding;
  background-color: $footer-bg;
  font-size: $font-size-base;
  display: flex;
  justify-content: center;

  span.gecko-ui-icon {
    color: $red-50;
  }

  .GeckoLink {
    color: $primary-color;
    text-decoration: none;
  }

  .help {
    position: absolute;
    left: $m-5;
  }

  @media (max-width: 720px) {
    justify-content: flex-end;
  }
}